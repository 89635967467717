import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import Layout from '../layouts/default';
import { Section } from 'src/components/molecules/Section';
export const query = graphql`
  query GithubMarkdownPage($slug: String!) {
    githubMarkdown(slug: { eq: $slug }) {
      title
      slug
      childMarkdownRemark {
        html
      }
    }
  }
`;
const MarkdownContainer = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    padding-top: 5rem;
    margin-top: -5rem;
  }

  ul {
    padding-left: 1.5em;
  }

  li > p,
  li > ul {
    margin-bottom: 0;
  }

  img {
    box-shadow: 0 9px 22px 0 rgba(0, 0, 0, 0.1);
  }

  .toc {
    font-size: ${({ theme }) => theme.smallFontSize};

    a {
      color: ${({ theme }) => theme.baseFontColor};
    }

    ul {
      list-style: none;
      padding-left: 0;

      ul {
        padding-left: 1.5em;

        ul {
          padding-left: 2em;
        }
      }
    }

    > ul > li {
      &:not(:last-child) {
        margin-bottom: 1rem;
      }

      > p > a {
        color: ${({ theme }) => theme.primaryColor};
        font-weight: bold;
        font-size: 1rem;
      }
    }
  }
`;

function GithubMarkdownPage({ data }) {
  return (
    <Layout
      pageContext={{
        frontmatter: {
          title: data.githubMarkdown.title,
          alternativeHeader: true,
        },
      }}
    >
      <Section>
        <MarkdownContainer
          dangerouslySetInnerHTML={{
            __html: data.githubMarkdown.childMarkdownRemark.html,
          }}
        />
      </Section>
    </Layout>
  );
}

export default GithubMarkdownPage;
